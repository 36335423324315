import React, { useEffect, useState } from "react";
import { useLocation, useMatch, useParams } from "react-router-dom";
import SharedDrawerMentee from "../../../components/shared/SharedDrawerMentee/SharedDrawerMentee";
import TerminateRelationship from '../../../components/shared/TerminateRelationship/MenteeTerminate';
import User from "../../../components/user/User";
import { baseURL } from "../../../config/config";

async function getMentor(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/matching/mymentor", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return null;
    return json.data;
}

function MentorPage() {
    const params = useParams();
    const { state } = useLocation();
    const userid = params?.id;
    const token = JSON.parse(localStorage.getItem('token'));
    const [mentor, setMentor] = useState();

    useEffect(() => {
        if (!token) return;
        (async () => {
            let mentor = await getMentor(token);
            if (mentor != null) setMentor(mentor);
        })();

    }, []);

    return (
        <>
            <SharedDrawerMentee token={token} />
            <User
                userId={userid}
                role={"mentor"}
                view={"page"}
                token={token}
                mode={"mentee"}
            />

            {
                (mentor?.userid == userid) ?
                    <TerminateRelationship token={token} /> : <></>}
        </>
    );
}

export default MentorPage;