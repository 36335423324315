import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import { baseURL } from '../../config/config';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopTimePicker from '@mui/lab/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {
	FieldWrapper,
	FormLabel,
	CreateMeetingWrapper,
	SelectWrapper,
	DateWrapper,
	ButtonWrapper
} from "./styles";
import { useNavigate } from 'react-router-dom';
import SharedButton from '../shared/SharedButton/SharedButton';

async function createSession(params, token, navigate) {
	let data = params;
	data.duration = parseInt(data.duration);
	data.topic = parseInt(data.topic);
	data.maxParticipants = parseInt(data.maxParticipants);

	const auth_header = "Bearer " + token;
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(data)
	};

	fetch(baseURL + "/event/create", request)
		.then(function (response) { return response.json(); })
		.then(function (data) {
			const items = data;

			if (items.responseCode === 200) {
				navigate("/mentor/home", { state: { message: "Success! Session has been created." } });
			}
		});
}

async function getTopics(token) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};

	var response = await fetch(baseURL + "/topics", request);
	var json = await response.json() ?? {};

	if (json.responseCode !== 200) return [];
	return json.data;
}

function Createsession({ token }) {

	const navigate = useNavigate();
	const [topics, setTopics] = useState([]);

	useEffect(() => {
		(async () => {
			let topics = await getTopics(token);
			if (topics != null) setTopics(topics);
		})();
	}, []);

	let keys = Object.keys(topics);

	const [values, setValues] = useState({
		topic: "-1",
		duration: "-1",
		location: "",
		type: "-1",
		participants: "",
		description: ""
	});

	const [errors, setErrors] = useState({
		topicError: false,
		dateError: false,
		durationError: false,
		locationError: false,
		typeError: false,
		participantError: false
	});

	const {
		topic,
		duration,
		location,
		type,
		participants,
		description
	} = values;

	const {
		topicError,
		dateError,
		durationError,
		locationError,
		typeError,
		participantsError

	} = errors;

	const dateTime = new Date();
	const [date, setDateValue] = React.useState(new Date());

	const handleChange = (name) => (e) => {
		e.preventDefault();
		let cValues = { ...values };
		let cErrors = { ...errors }

		if (name === "topic" && errors["topicError"]) {
			cErrors["topicError"] = false;
		}
		if (name === "date" && errors["dateError"]) {
			cErrors["dateError"] = false;
		}
		if (name === "duration" && errors["durationError"]) {
			cErrors["durationError"] = false;
		}
		if (name === "location" && errors["locationError"]) {
			cErrors["locationError"] = false;
		}
		if (name === "type" && errors["typeError"]) {
			cErrors["typeError"] = false;
		}
		if (name === "participants" && errors["participantsError"]) {
			cErrors["participantsError"] = false;
		}

		if (name === "topic") {
			cValues[name] = e.target.value;
		} else {
			cValues[name] = e.target.value;
		}

		setValues(cValues);
		setErrors(cErrors);
	};

	const handleCreate = (e) => {
		if (!validateMeetingInfo()) {
			return;
		}

		let params = {
			topic: topic,
			date: date,
			duration: duration,
			maxParticipants: participants,
			description: description,
			location: location,
			type: type
		}

		createSession(params, token, navigate);
	}

	const validateMeetingInfo = () => {
		let cErrors = { ...errors }
		let flag = true;

		if (topic === "-1") {
			cErrors["topicError"] = true;
			flag = false;
		}
		if (date === "" || date < dateTime) {
			cErrors["dateError"] = true;
			flag = false;
		}
		if (duration === "-1") {
			cErrors["durationError"] = true;
			flag = false;
		}
		if (location === "") {
			cErrors["locationError"] = true;
			flag = false;
		}
		if (participants === "" || participants < 1 || participants > 500 || isNaN(participants)) {
			cErrors["participantsError"] = true;
			flag = false;
		}
		if (type === "-1") {
			cErrors["typeError"] = true;
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	const durationOptions = [
		{ value: 10, label: '10 Minutes' },
		{ value: 20, label: '20 Minutes' },
		{ value: 30, label: '30 Minutes' },
		{ value: 45, label: '45 Minutes' },
		{ value: 60, label: '60 Minutes' },
		{ value: 90, label: '90 Minutes' }
	]

	const typeOptions = [
		{ value: "Group", label: "Group" },
		{ value: "Workshop", label: "Workshop" }
	]

	return (
		<CreateMeetingWrapper>
			<FieldWrapper item>
				<FormLabel>Session Topic</FormLabel>
				<SelectWrapper>
					<TextField
						defaultValue={"-1"}
						label="Topic"
						id="topic"
						error={topicError}
						onChange={handleChange("topic")}
						SelectProps={{ native: true }}
						helperText={topicError ? "Please choose a topic for the meeting." : ""}
						required
						select
						fullWidth
					>
						<option value="-1" disabled hidden>
							Select...
						</option>
						{keys.map((key) => {
							return <option
								key={key}
								value={key}
							>
								{topics[key]}
							</option>
						})}

					</TextField>
					<FormHelperText></FormHelperText>
				</SelectWrapper>
			</FieldWrapper>
			<FieldWrapper>
				<FormLabel>Time and Date</FormLabel>
				<DateWrapper>
					<LocalizationProvider
						dateAdapter={AdapterDateFns}>
						<DesktopTimePicker
							clearable
							id="date"
							error={dateError}
							value={date}
							helperText={dateError ? "Please choose a valid date/time." : ""}
							onChange={(newValue) => {
								setDateValue(newValue);
							}}
							minDateTime={new Date()}
							renderInput={(params) => <TextField {...params} />}
							fullWidth
						/>
					</LocalizationProvider>
				</DateWrapper>
			</FieldWrapper>
			<FieldWrapper>
				<FormLabel>Duration</FormLabel>
				<SelectWrapper>
					<FormControl fullWidth>
						<TextField
							defaultValue={"-1"}
							select
							required
							id="duration"
							options={durationOptions}
							error={durationError}
							SelectProps={{ native: true }}
							helperText={durationError ? "Please choose a meeting duration." : ""}
							onChange={handleChange("duration")}
							label="Duration"
							fullWidth
						>
							<option value="-1" disabled hidden>
								Select...
							</option>
							{durationOptions.map(option => (
								<option
									key={option.value}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</TextField>
						<FormHelperText></FormHelperText>
					</FormControl>
				</SelectWrapper>
			</FieldWrapper>
			<FieldWrapper>
				<FormLabel>Location</FormLabel>
				<TextField
					error={locationError}
					helperText={locationError ? "Please enter a location for the meeting." : ""}
					required
					fullWidth
					name="location"
					label="Location"
					id="location"
					onChange={handleChange("location")}
				/>
			</FieldWrapper>

			<FieldWrapper>
				<FormLabel>Session Type</FormLabel>
				<SelectWrapper>
					<FormControl fullWidth>
						<TextField
							defaultValue={"-1"}
							select
							required
							id="type"
							error={typeError}
							SelectProps={{ native: true }}
							helperText={typeError ? "Please choose a meeting type." : ""}
							onChange={handleChange("type")}
							label="Type"
							fullWidth
						>
							<option value="-1" disabled hidden>
								Select...
							</option>
							{typeOptions.map(option => (
								<option
									key={option.value}
									value={option.value}
								>
									{option.label}
								</option>
							))}
						</TextField>
					</FormControl>
				</SelectWrapper>
			</FieldWrapper>

			<FieldWrapper>
				<FormLabel>Maximum Number of Participants</FormLabel>
				<TextField
					inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
					error={participantsError}
					helperText={participantsError ? "Please enter a number between 1 and 500." : ""}
					required
					fullWidth
					name="participants"
					label="Participants"
					id="participants"
					onChange={handleChange("participants")}
				/>
			</FieldWrapper>

			<FieldWrapper>
				<FormLabel>Session Description</FormLabel>
				<TextField
					id="description"
					label="Description"
					name="description"
					multiline
					rows={3}
					fullWidth
					value={description}
					onChange={handleChange("description")}
				/>
			</FieldWrapper>

			<ButtonWrapper>
				<SharedButton
					buttonType={"Action"}
					width={"Short"}
					onClick={handleCreate}
				>
					Submit
				</SharedButton>
			</ButtonWrapper>
		</CreateMeetingWrapper>
	)
}

export default Createsession;