import React, { useEffect, useState } from "react";
import { baseURL } from "../../config/config";
import { MenuItem, TextField } from "@mui/material";
import SharedButton from "../shared/SharedButton/SharedButton";
import User from "../user/User";
import {
    ButtonWrapper,
    ChooseMentorContainer,
    FilterContainer,
    ListContainer,
    Subheading,
    Title,
    TitleContainer
} from "./styles";

// Call API to get topics
async function getTopics(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    }

    return fetch(baseURL + "/topics", request)
        .then((res) => res.json());
}

// Call API to get list of recommended mentors for mentee
async function findMentors(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    }

    return fetch(baseURL + "/matching/findmentors", request)
        .then((res) => res.json());
}

// Call API to get profile of mentor
async function getMentor(token, id) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    }

    return fetch(baseURL + "/profile/user/" + id, request)
        .then((res) => res.json());
}


function ChooseMentor({token}) {
    const [mentorProfiles, setMentorProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [topics, setTopics] = useState([]);
    const [filter, setFilter] = useState(1);

    useEffect(() => {
        if (!token) {
            window.location.href = "/login";
            return;
        }

        setIsLoading(true);
        getMentors(token)
            .then((res) => {
                setIsLoading(false);
            });

        // Get topics for filter
        getData(token);
    }, []);

    const getMentors = async (token) => {
        let cMentorProfiles = { ...mentorProfiles };

        const mentors = await findMentors(token)
            .then((res) => {
                if (res.responseCode === 200) {
                    const mentors = res.data;
                    let promises = Object.keys(mentors).map((key) => { return getMentor(token, mentors[key].userid) });
                    Promise.all(promises)
                        .then((res) => {
                            const cMentorProfiles = res.map((mentor) => {
                                return mentor.data
                            });
                            for (let i = 0; i < cMentorProfiles.length; i++) {
                                if (cMentorProfiles[i] != null) {
                                    cMentorProfiles[i].proficiency_ratings = mentors[i].proficiency_ratings;
                                }
                            }
                            setMentorProfiles(cMentorProfiles);
                        });

                    return cMentorProfiles;
                } else {
                    alert("Failed to get recommended mentors.");
                    return;
                }
            })
    }

    const getData = async (token) => {
        const topics = await getTopics(token)
            .then((res) => {
                if (res.responseCode === 200) {
                    setTopics(res.data);
                }
            })
    }

    const onFilterChange = (e) => {
        e.preventDefault();
        setFilter(parseInt(e.target.value));
    }

    const areas = Object.keys(topics).map((key) => {
        return (
            <MenuItem value={key}>{topics[key]}</MenuItem>
        )
    });

    return (
        <ChooseMentorContainer>
            <TitleContainer>
                <Title>Choose your Mentor</Title>
            </TitleContainer>
            <ListContainer>
            {/* some problem here with filtering not working on first render. might be the mapping of topics not being done fast enough? */}
                {!isLoading && mentorProfiles.length > 0 ?
                    mentorProfiles.map((mentor) => {
                        if (mentor != null) {
                            const name = mentor.firstname ? mentor.firstname : "" +
                                mentor.lastname ? mentor.lastname : "";
                            return (
                                <User
                                    userId={mentor.userID}
                                    role={"mentor"}
                                    view={"card"}
                                    proficiency_ratings={mentor.proficiency_ratings}
                                />
                            )
                        }
                    }) : <></>
                }
            </ListContainer>
            <ButtonWrapper>
                <SharedButton width={"Long"} buttonType="Action" onClick={() => window.location.href="/mentee/home"} >Skip for now</SharedButton>
            </ButtonWrapper>
        </ChooseMentorContainer>
    );
}

export default ChooseMentor;