import React, { useEffect, useState } from "react";
import {
    Avatar,
    CardContent,
    Grid,
    Typography,
    Rating,
    Stack
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SharedButton from "../shared/SharedButton/SharedButton";
import {
    AboutWrapper,
    AvatarContainer,
    BodyText,
    ButtonWrapper,
    CardStyled,
    CardSubtitle,
    CardText,
    CardTextContainer,
    CardTitle,
    IconContainer,
    IconTextContainer,
    LinearProgressStyled,
    MilestoneWrapper,
    ProgressLabel,
    StatusWrapper,
    Subsubheading,
    Subtitle,
    TaskWrapper,
    UserDetails,
    UserPageWrapper
} from "./styles";
import { baseURL } from "../../config/config";
import MilestoneFull from "../planofaction/MilestoneFull";

/* 
    Get user based on userId. `role` to show whether profile is of a mentee, mentor,
    or general user. `view` determines whether whole page profile or user card is 
    rendered.
*/

async function getUserProfile(token, userId) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    }

    return fetch(baseURL + "/profile/user/" + userId, request)
        .then((res) => res.json());
}

async function getMenteeActionPlan(token, menteeId) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
    }

    return fetch(baseURL + "/feedback/menteeplan/" + menteeId, request)
        .then((res) => res.json());
}

async function initiateMentorship(token, params) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
        body: JSON.stringify(params)
    }

    // TODO: Get a working API
    return fetch(baseURL + "/matching/initiate/", request)
        .then((res) => res.json());
}

function User(props) {
    const [token, setToken] = useState('');
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [actionPlan, setActionPlan] = useState();

    const proficiency_ratings = props.proficiency_ratings ?? [];

    const {
        userId,
        role,
        view,
        type,
        mode
    } = props

    useEffect(() => {
        const cToken = JSON.parse(localStorage.getItem('token'));

        if (!cToken) {
            window.location.href = "/login";
            return;
        }
        setToken(cToken);

        // Get user data
        setIsLoading(true);

        getUserProfile(cToken, userId)
            .then((res) => {
                if (res.responseCode !== 200) {
                    console.log("Error. Unable to get user with id " + userId);
                    return;
                }
                setUser(res.data);
                setIsLoading(false);
                return res.data;
            })
            .then((res) => {
                if (role == "mentee" && view == "page") {
                    setIsLoading(true);
                    getActionPlan(cToken, res?.menteeProfile?.menteeid);
                    setIsLoading(false);
                }
            })

    }, []);


    const getActionPlan = (token, menteeId) => {
        // const menteeId = user?.menteeProfile?.menteeid;
        const actionPlan = getMenteeActionPlan(token, menteeId)
            .then((res) => {
                if (res.responseCode !== 200) {
                    console.log("Error: Unable to get action plan of mentee with id " + menteeId);
                    return;
                }
                const cActionPlan = res.data.reduce((acc, curr) => {
                    if (curr.milestoneid) {
                        const milestoneid = curr.milestoneid;
                        const currentItems = acc[milestoneid];

                        return {
                            ...acc,
                            [milestoneid]: currentItems ? [...currentItems, curr] : [curr]
                        };
                    }
                    return acc;
                }, {})

                setActionPlan(cActionPlan);

                setIsLoading(false);
            })
    };

    const MenteeProfilePage = () => {
        const actionPlanList = () => {
            return (
                <>
                    {Object.keys(actionPlan).length > 0 ?
                        Object.keys(actionPlan).map((milestoneId) => {
                            const tasks = actionPlan[milestoneId];
                            let completed = 0;

                            tasks.map((task) => {
                                if (task.taskstatus == "Complete") {
                                    completed += 1;
                                }
                            })

                            const milestone_name = tasks[0].milestonename;

                            return (
                                <MilestoneWrapper container>
                                    <CardStyled style={{ padding: "20px 40px" }}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Subsubheading>{milestone_name}</Subsubheading>
                                                </Grid>
                                                <Grid container xs={8} padding={"15px 20px"}>
                                                    <Grid item xs={8}>
                                                        <LinearProgressStyled variant="determinate" color="secondary" value={100 * (completed / tasks.length)} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {(tasks[0].deadlinedata !== null) ?
                                                            ((100 * (completed / tasks.length) === 100)
                                                                ? (<ProgressLabel>Completed!</ProgressLabel>)
                                                                : (<ProgressLabel>{completed}/{tasks.length} tasks</ProgressLabel>))
                                                            : (<ProgressLabel>No Tasks added</ProgressLabel>)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                {tasks.map((task) => {
                                                    return (
                                                        <TaskWrapper key={task} container>
                                                            <Grid item xs>
                                                                <BodyText>{task.description}</BodyText>
                                                            </Grid>
                                                            <StatusWrapper status={task.taskstatus} item xs={"auto"}>
                                                                <Typography>{task.taskstatus}</Typography>
                                                            </StatusWrapper>
                                                        </TaskWrapper>
                                                    )
                                                })}
                                            </Grid>
                                        </CardContent>
                                    </CardStyled>
                                </MilestoneWrapper>

                            )

                        })
                        :
                        <BodyText>Mentee has not created plan of action yet.</BodyText>
                    }
                </>
            )
        };

        return (
            !isLoading && user ?
                <UserPageWrapper>
                    <UserDetails container borderRadius={5}>
                        <AvatarContainer item xs="auto">
                            <Avatar
                                sx={{ minWidth: "100px", minHeight: "100px", width: "10vw", height: "10vw" }}
                                src={user.profilepicture}
                            />
                        </AvatarContainer>
                        <CardTextContainer item xs>
                            <CardTitle>{user.firstname + " " + user.secondname}</CardTitle>
                            <IconTextContainer>
                                <IconContainer>
                                    <WorkIcon />
                                </IconContainer>
                                <CardSubtitle>{user.jobposition + ", " + user.department}</CardSubtitle>
                            </IconTextContainer>
                            <IconTextContainer>
                                <IconContainer>
                                    <EmailIcon />
                                </IconContainer>
                                <CardText>{user.email}</CardText>
                            </IconTextContainer>
                        </CardTextContainer>
                    </UserDetails>
                    <AboutWrapper>
                        <IconTextContainer black={true}>
                            <IconContainer>
                                <PersonIcon />
                            </IconContainer>
                            <Subtitle>About</Subtitle>
                        </IconTextContainer>
                        <BodyText>{user.menteeProfile.bio}</BodyText>
                    </AboutWrapper>
                    {
                        !isLoading && actionPlan ?
                            <AboutWrapper>
                                <IconTextContainer black={true}>
                                    <IconContainer>
                                        <PersonIcon />
                                    </IconContainer>
                                    <Subtitle>Plan of Action</Subtitle>
                                </IconTextContainer>
                                {actionPlanList()}
                            </AboutWrapper> : <></>
                    }
                </UserPageWrapper> : null
        )
    }


    const MentorProfilePage = () => {
        return (
            !isLoading && user ?
                <UserPageWrapper>
                    <UserDetails container borderRadius={5}>
                        <AvatarContainer item xs="auto">
                            <Avatar
                                sx={{ minWidth: "100px", minHeight: "100px", width: "10vw", height: "10vw" }}
                                src={user.profilepicture}
                            />
                        </AvatarContainer>
                        <CardTextContainer item xs>
                            <CardTitle>{user.firstname + " " + user.secondname}</CardTitle>
                            <IconTextContainer>
                                <IconContainer>
                                    <WorkIcon />
                                </IconContainer>
                                <CardSubtitle>{user.jobposition + ", " + user.department}</CardSubtitle>
                            </IconTextContainer>
                            <IconTextContainer>
                                <IconContainer>
                                    <EmailIcon />
                                </IconContainer>
                                <CardText>{user.email}</CardText>
                            </IconTextContainer>
                        </CardTextContainer>
                    </UserDetails>
                    <AboutWrapper>
                        <IconTextContainer black={true}>
                            <IconContainer>
                                <PersonIcon />
                            </IconContainer>
                            <Subtitle>About</Subtitle>
                        </IconTextContainer>
                        <BodyText>{user.mentorProfile?.bio}</BodyText>
                    </AboutWrapper>
                </UserPageWrapper> : null
        )
    }

    const onMentorSelect = (mentorid) => async (e) => {
        e.preventDefault();

        // Initiate relationship with mentor
        let params = {
            mentorID: mentorid,
        }

        await initiateMentorship(token, params)
            .then((res) => {
                if (res.responseCode === 200) {
                    window.location.href = "/mentee/home";
                    return;
                }
                console.log("Unable to initiate new relationship");
                return;
            });
    }

    const onMenteeView = (userid) => (e) => {
        e.preventDefault();
        window.location.href = `/users/mentees/${userid}`;
    }

    const onMentorView = (userid) => (e) => {
        e.preventDefault();
        if (mode == "mentee") {
            window.location.href = `/users/mentors/${userid}`;
        } else {
            window.location.href = `/mentor/profile`;
        }
    }

    const MentorProfileCard = () => {
        const ratingsToRender = [];
        [1, 2, 3, 4].forEach(key => {
            if (proficiency_ratings[key] != null) {
                switch (key) {
                    case 1:
                        ratingsToRender.push({ name: "Time Management", rating: proficiency_ratings[key] }); break;
                    case 2:
                        ratingsToRender.push({ name: "Leadership", rating: proficiency_ratings[key] }); break;
                    case 3:
                        ratingsToRender.push({ name: "Organisation", rating: proficiency_ratings[key] }); break;
                    default:
                        ratingsToRender.push({ name: "Project Management", rating: proficiency_ratings[key] });
                }
            }
        });

        return (
            !isLoading && user ?
                <CardStyled variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <AvatarContainer item xs="auto">
                                <Avatar
                                    sx={{ minWidth: "100px", minHeight: "100px", width: "10vw", height: "10vw" }}
                                    alt="Profile picture"
                                    src={user.profilepicture} />
                            </AvatarContainer>
                            <Grid item xs>
                                <CardTitle>{user.firstname + " " + user.secondname}</CardTitle>
                                <CardSubtitle>{user.jobposition + ", " + user.department}</CardSubtitle>
                                <CardText>{user.mentorProfile?.bio}</CardText>
                                <Stack spacing={2} direction="column">
                                    {ratingsToRender.map(r => (
                                        <Stack spacing={2} direction="row">
                                            <CardSubtitle>{r.name}</CardSubtitle>
                                            <Rating
                                                size="large"
                                                name={r.name}
                                                value={r.rating}
                                                readOnly
                                            />
                                        </Stack>
                                    ))}
                                </Stack>
                            </Grid>
                            <Grid item xs="auto">
                                <ButtonWrapper item xs={2}>
                                    {type == "view" ?
                                        <SharedButton
                                            buttonType="Action"
                                            width="Short"
                                            onClick={onMentorView(user.userID)}
                                        >
                                            View
                                        </SharedButton> :
                                        <SharedButton
                                            buttonType="Action"
                                            width="Short"
                                            onClick={onMentorSelect(user.mentorProfile?.mentorid)}
                                        >
                                            Select
                                        </SharedButton>
                                    }
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardStyled> : null
        )
    }



    const MenteeProfileCard = () => {
        return (
            !isLoading && user ?
                <CardStyled variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <AvatarContainer item xs="auto">
                                <Avatar
                                    sx={{ minWidth: "50px", minHeight: "50px", width: "5vw", height: "5vw" }}
                                    alt="Profile picture"
                                    src={user.profilepicture}
                                />
                            </AvatarContainer>
                            <CardTextContainer item xs>
                                <CardTitle>{user.firstname + " " + user.secondname}</CardTitle>
                                <CardSubtitle>{user.topicname}</CardSubtitle>
                            </CardTextContainer>
                            <ButtonWrapper item xs="auto">
                                <SharedButton
                                    buttonType="Action"
                                    width="Short"
                                    onClick={onMenteeView(user.userID)}
                                >
                                    View
                                </SharedButton>
                            </ButtonWrapper>
                        </Grid>
                    </CardContent>
                </CardStyled> : null
        )
    }

    return (
        <>
            {
                role == "mentee" ?
                    view == "page" ? MenteeProfilePage() : MenteeProfileCard()
                    : role == "mentor" ?
                        view == "page" ? MentorProfilePage() : MentorProfileCard()
                        : <></>
            }
        </>
        // UserCardMedium(name, bio, areaOfExpertise, profilePhoto)
    )
}

export default User;