import TextField from '@mui/material/TextField';
import React, {setState, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { baseURL } from '../../config/config';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopTimePicker from '@mui/lab/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Navigate, useNavigate } from 'react-router-dom';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import {
	FieldWrapper,
	FormLabel,
	HeaderOne,
	CreateMeetingWrapper,
	SelectWrapper,
	DateWrapper
} from "./styles";
import { WindowSharp } from '@mui/icons-material';


async function createMeeting(params, token, navigate) {
	let data = params;
	data.duration = parseInt(data.duration);
	data.topic = parseInt(data.topic);

	const auth_header = "Bearer " + token;
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(data)
	};

	fetch(baseURL + "/event/create", request)
		.then(function(response){ return response.json(); })
		.then(function(data) {
			const items = data;				
			if (items.responseCode === 200) {
				navigate("/mentee/home", { state: { message: "Meeting successfully created! Your mentor must approve the meeting before it can be confirmed." } })
			}
		});
}

async function getTopics(token) {
    const auth_header = "Bearer " + token;
    const request = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
    };

    var response = await fetch(baseURL + "/topics", request);
    var json = await response.json() ?? {};

    if (json.responseCode !== 200) return [];
    return json.data;
}


function Createmeeting({ token }) {

	const navigate = useNavigate();
	const [topics, setTopics] = useState([]);

    useEffect(() => {
        (async () => {
            let topics = await getTopics(token);

            if (topics != null) setTopics(topics);

        })();
    }, []);

	let keys = Object.keys(topics);
	
	const [values, setValues] = useState({
		topic: "-1",
		//date: "",
		duration: "-1",
		location: "",
		description: ""
	});

	const [errors, setErrors] = useState({
		topicError: false,
		dateError: false,
		durationError: false,
		locationError: false,
	});

	const {
		topic,
		//date,
		duration,
		location,
		description
    } = values;

	const {
        topicError,
        dateError,
        durationError,
        locationError
    } = errors;

	const dateTime = new Date();
	const [date, setDateValue] = React.useState(new Date());

	const handleChange = (name) => (e) => {
		e.preventDefault();
		let cValues = { ...values };
        let cErrors = { ...errors }

		if (name == "topic" && errors["topicError"]) {
            cErrors["topicError"] = false;
        }
		if (name == "date" && errors["dateError"]) {
            cErrors["dateError"] = false;
        }
		if (name == "duration" && errors["durationError"]) {
            cErrors["durationError"] = false;
        }
        if (name == "location" && errors["locationError"]) {
            cErrors["locationError"] = false;
        }

		if (name == "topic") {
			cValues[name] = e.target.value;
		} else {
			cValues[name] = e.target.value;
		}
        
        setValues(cValues);
        setErrors(cErrors);
	};

	const handleCreate = (e) => {
		if (!validateMeetingInfo()) {
			return;
		}

		let params = {
			topic: topic,
			date: date,
			duration: duration,
			type: "1-1",
			location: location,	
			description: description
		}

		createMeeting(params, token, navigate);
	}

	const validateMeetingInfo = () => {
		let cErrors = { ...errors }
		let flag = true;

		if (topic == "-1") {
			cErrors["topicError"] = true;
			flag = false;
		}
		if (date == "" || date < dateTime) {
            cErrors["dateError"] = true;
            flag = false;
        }
		if (duration == "-1") {
			cErrors["durationError"] = true;
			flag = false;
		}
		if (location == "") {
			cErrors["locationError"] = true;
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	const durationOptions = [
		{ value: 10, label: '10 Minutes'},
		{ value: 20, label: '20 Minutes'},
		{ value: 30, label: '30 Minutes'},
		{ value: 45, label: '45 Minutes'},
		{ value: 60, label: '60 Minutes'},
		{ value: 90, label: '90 Minutes'}
	]

		
	return (
		<>
			<CreateMeetingWrapper container>
				<Grid>
					<Grid>
						<Grid>							
							<FieldWrapper>
								<FormLabel>Meeting Topic</FormLabel>	
								<SelectWrapper>	
									<TextField
										defaultValue={"-1"}
										label="Topic"								
										id="topic"
										error={topicError}
										onChange={handleChange("topic")}											
										SelectProps={{native: true}}
										helperText={topicError ? "Please choose a topic for the meeting." : ""}
										fullWidth
										required
										select
									>
										<option value="-1" disabled>
											Select...
										</option>
										{keys.map((key) => {
											return <option
												//key={topics[key].topicid}
												key={key}
												value={key}
											>
												{topics[key]}
											</option>
										})}
										
									</TextField>							
									<FormHelperText></FormHelperText>
								</SelectWrapper>
							</FieldWrapper>
						
							<FieldWrapper>	
								<FormLabel>Time and Date</FormLabel>	
								<DateWrapper>	
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopTimePicker
											clearable
											id="date"
											error={dateError}
											value={date}	
											helperText={dateError ? "Please choose a valid date/time.": ""}						
											onChange={(newValue) => {
												setDateValue(newValue);
											}}
											minDateTime={new Date()}								
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</DateWrapper>
							</FieldWrapper>	
						
							<FieldWrapper>		
								<FormLabel>Duration</FormLabel>		
								<SelectWrapper>			
									<FormControl fullWidth>
										<TextField
											defaultValue={"-1"}
											select
											required
											id="duration"
											options={durationOptions}
											error={durationError}
											SelectProps={{native: true}}
											helperText={durationError ? "Please choose a meeting duration": ""}	
											onChange={handleChange("duration")}
											label="Duration"
										>			
											<option value="-1" disabled>
												Select...
											</option>
											{durationOptions.map(option => (
												<option
													key={option.value}
													value={option.value}
												>
													{option.label}
												</option>
											))}	
										</TextField>			
										<FormHelperText></FormHelperText>
									</FormControl>
								</SelectWrapper>	
							</FieldWrapper>	

							<FieldWrapper>		
								<FormLabel>Location</FormLabel>
								<TextField
									//margin="normal"
									error={locationError}
									helperText={locationError ? "Please enter a location for the meeting." : ""}
									required
									fullWidth
									name="location"
									label="Location"
									id="location"
									onChange={handleChange("location")}
									//autoComplete="current-password"
								/>
							</FieldWrapper>	
						
							<FieldWrapper>				
								<FormLabel>Meeting Description</FormLabel>
								<TextField
									id="description"
									label="Description"
									name="description"
									multiline
									rows={3}
									fullWidth
									value={description}
									//onChange={setDescriptionValue}
									onChange={handleChange("description")}
								/>
							</FieldWrapper>
						
							{/* Material UI has loadingButton can add later for better UX */}
							<Button
								onClick={handleCreate}
								variant="contained"
								fullWidth
								sx={{ mt: 1, mb: 2, color: "#ffffff"}}
							>
								Create Meeting
							</Button>
						</Grid>
					</Grid>
				</Grid>

			</CreateMeetingWrapper>
		</>
	)
}

export default Createmeeting;