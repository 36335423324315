import React, {useState, useEffect} from 'react';
import { Button, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, Typography, Stack } from "@mui/material";
import Color from "../../assets/colors";
import MilestoneFull from "./MilestoneFull";
import { PlanOfActionWidget, PlanOfActionWidgetFull, Title } from "./styles";
import SharedButton from "../shared/SharedButton/SharedButton";
import { baseURL } from '../../config/config';

async function getPlan(params) {
	const auth_header = "Bearer " + params.loginToken;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	};
	var response = await fetch(baseURL + "/feedback/myplan", request)
	return response.json();
}

const PlanOfActionFull = ({ setToken, token }) => {
    //const { title, tasks } = props;
    const [tasks, setTasks] = useState([]);
    const [milestoneIDs, setMilestoneIDs] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    var getData = async () => {
        const res = await getPlan({ loginToken: token });
        if (res.responseCode === 200) {
            setTasks(res.data);
            setMilestoneIDs([...new Set(res.data.map(task => task.milestoneid))]);
        }
    };

    return (
        <PlanOfActionWidgetFull minWidth={600} minHeight={200}>
            <Grid container spacing={5}>
                {milestoneIDs.map(mid => (
                    <Grid item xs={12} md={6} key={mid}>
                        <MilestoneFull milestoneID={mid} tasks={tasks} token={token} />
                    </Grid> 
                ))}
            </Grid>
        </PlanOfActionWidgetFull>
    )
}

export default PlanOfActionFull;