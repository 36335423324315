export const convertDate = function (date) {
    let d = new window.Date(date);
    let dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    
    let cDate = d.toLocaleDateString('default', dateOptions);
    let cTime = d.toLocaleTimeString('default', { hour12: true, hour: '2-digit', minute: '2-digit' });
    
    let string = `${cTime}  |  ${cDate}`;
    return string;
};

export const truncate = (str, n) => {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1);
    return subString.substr(0, subString.lastIndexOf(" ")) + "...";
};