import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
	BoxStyled,
	ButtonWrapper,
	Caption,
	ErrorDialogWrapper,
	FieldWrapper,
	FormLabel,
	FormWrapper,
	HeaderOne,
	LinkStyled,
	LinksWrapper,
	RegisterContainer,
	RegisterWrapper,
	RegistrationWrapper,
} from "./styles";
import SharedButton from '../../shared/SharedButton/SharedButton';
import { baseURL } from '../../../config/config';
import RegistrationSteps from '../RegistrationSteps/RegistrationSteps';
import SharedErrorDialog from '../../shared/SharedErrorDialog/SharedErrorDialog';
import { ErrorDialogContainer } from '../../shared/SharedErrorDialog/styles';
import { useLocation, useNavigate } from 'react-router-dom';

/* Flow of registration 
 * PAGE 0 gets user's email and password, calls `handleRegister` without 
 * making any API requests
 * PAGE 1 lets user choose whether to register as mentee or mentor without 
 * making any API requests
 * PAGE 2 gets user's basic info and calls API /auth/register to create 
 * new user. Calls `handleNext` with activeStep == 2
 * PAGE 3 gets user's info as mentor/mentee and calls API /profile/mentee or
 * /profile/mentor to register user as a mentee or mentor. Calls `handleSubmit`
 */

// Call API to register basic user and retrieve token
async function registerUser(params) {
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(params)
	};

	return fetch(baseURL + "/auth/register", request)
		.then(res => res.json());
}

// For registering a user as a mentee
async function registerMentee(token, params) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(params)
	}

	return fetch(baseURL + "/profile/mentee", request)
		.then(res => res.json());
}

// For registering a user as a mentor
async function registerMentor(token, params) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(params)
	}

	return fetch(baseURL + "/profile/mentor", request)
		.then(res => res.json());
}

async function loginUser(params) {
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(params)
	};

	return fetch(baseURL + "/auth/login", request)
		.then(res => res.json());
}

async function getTopics(token) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header }
	}

	return fetch(baseURL + "/profile/topics", request)
		.then((res) => res.json());
}

async function sendProficiencies(token, params) {
	const auth_header = "Bearer " + token;
	const request = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', 'Authorization': auth_header },
		body: JSON.stringify(params)
	};

	return fetch(baseURL + "/matching/proficiency", request)
		.then(res => res.json());
}

function RegisterMain({ setToken }) {
	const navigate = useNavigate();
	const [values, setValues] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		role: "",
		firstName: "",
		lastName: "",
		businessArea: "",
		jobTitle: "",
		profilePhoto: "",
		profileDescription: "",
		topics: [],
		proficiencies: {},
		eduStyle: "",
	});

	const {
		email,
		password,
		confirmPassword,
		role,
		firstName,
		lastName,
		businessArea,
		jobTitle,
		profilePhoto,
		profileDescription,
		topics,
		proficiencies,
		eduStyle,
	} = values;

	const [errors, setErrors] = useState({
		emailError: false,
		passwordError: false,
		confirmPasswordError: false,
		firstNameError: false,
		lastNameError: false,
		businessAreaError: false,
		jobTitleError: false,
		topicsError: false,
		eduStyleError: false,
		profileDescriptionError: false,
	});

	const {
		emailError,
		passwordError,
		confirmPasswordError,
		firstNameError,
		lastNameError,
		businessAreaError,
		jobTitleError,
		topicsError,
		eduStyleError,
		profileDescriptionError
	} = errors;

	const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");
	const [passwordMessage, setPasswordMessage] = useState("");
	const [emailMessage, setEmailMessage] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [showError, setShowError] = useState(false);
	const [cToken, setcToken] = useState("");

	const handleRoleChange = (role) => {
		if (
			role == null ||
			role == undefined ||
			role == ""
		) {
			return;
		}

		let cValues = { ...values };
		cValues["role"] = role;
		setValues(cValues);
	}

	const { state } = useLocation();

	useEffect(() => {
		if (state?.role) {
			handleRoleChange(state.role);
			setActiveStep(3);
		}
	}, []);

	// Store email and password in state
	const handleChange = (name) => (e, value) => {
		let cValues = { ...values };
		let cErrors = { ...errors }

		if (name == "email" && errors["emailError"]) {
			cErrors["emailError"] = false;
		}
		if (name == "password" && errors["passwordError"]) {
			cErrors["passwordError"] = false;
		}
		if (name == "confirmPassword" && errors["confirmPasswordError"]) {
			cErrors["confirmPasswordError"] = false;
		}
		if (name == "firstName" && errors["firstNameError"]) {
			cErrors["firstNameError"] = false;
		}
		if (name == "lastName" && errors["lastNameError"]) {
			cErrors["lastNameError"] = false;
		}
		if (name == "businessArea" && errors["businessAreaError"]) {
			cErrors["businessAreaError"] = false;
		}
		if (name == "jobTitle" && errors["jobTitleError"]) {
			cErrors["jobTitleError"] = false;
		}
		if (name == "profileDescription" && errors["profileDescriptionError"]) {
			cErrors["profileDescriptionError"] = false;
		}

		cValues[name] = e.target.value;
		setValues(cValues);
		setErrors(cErrors);
	};

	const handleTopicsChange = (e) => {
		let cValues = { ...values };
		let cErrors = { ...errors };
		const {
			target: { value },
		} = e;

		if (errors["topicsError"]) {
			cErrors["topicsError"] = false;
		}

		cValues["topics"] = typeof value === 'string' ? value.split(',') : value;
		setValues(cValues);
		setErrors(cErrors);
	}

	const handleProfileDescChange = (desc) => {
		const length = desc.length;
		if (length > 150) {
			desc = desc.substring(0, 150);
		}

		let cValues = { ...values };
		cValues["profileDescription"] = desc;
		setValues(cValues);
	}

	const handleMetadataChange = (name) => (e) => {
		let cValues = { ...values };

		if (name == "eduStyle") {
			cValues["eduStyle"] = e.target.value;
		}

		if (name == "proficiency") {
			cValues["proficiencies"][e.target.name] = parseInt(e.target.value);
		}

		setValues(cValues);
		
	}

	const handleNext = async e => {
		if (e) {
			e.preventDefault();
		}

		// Page for getting basic user information
		if (activeStep == 2) {
			if (!validateBasicInfoParams()) {
				return;
			}

			// Register basic user
			let params = {
				email: email,
				password: password,
				firstname: firstName,
				secondname: lastName,
				businessarea: businessArea,
				jobtitle: jobTitle,
				profilepicture: profilePhoto,
			}

			const res = await registerUser(params);

			if (res.responseCode !== 200) {
				setErrorMessage(res.data != null ? "Error: User already exists with this email." : "Error: Unable to register new user.");
				setShowError(true);
				return;
			}

			const token = await loginUser(params)
				.then((res) => {
					if (res.responseCode === 200) {
						setToken(res.data.token);
						setcToken(res.data.token);
					}
				})

			if (token) {
				const proficiencies = await getTopics(cToken)
					.then((res) => {
						if (res.responseCode === 200) {
							let cValues = { ...values };
							cValues[proficiencies] = res.data
							Object.keys(res.data).map((key) => {
								cValues[proficiencies][key] = 0
							})
							setValues(cValues);
						}
					})
			}
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setShowError(false)
	};

	const handleBack = (e) => {
		if (e) {
			e.preventDefault();
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		setShowError(false)
	};

	// When register button clicked
	const handleRegister = (e) => {
		e.preventDefault();

		if (!validateEmailAndPassword()) {
			setErrorMessage("Error: Failed to register.");
			setShowError(true);
			return;
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setShowError(false)
	}

	// After user fills in info for mentor/mentee registration
	const handleSubmit = async e => {
		e.preventDefault();

		// Handle registration based on mentor or mentee
		if (role == "mentor") {
			let metadata = {
				eduStyle: eduStyle,
			}

			let params = {
				bio: profileDescription,
				topics: topics.map(Number),
				metadata: JSON.stringify(metadata),
				takingMoreBookings: true,
			}
			const profileId = await registerMentor(state?.token ?? cToken, params)
				.then((res) => {
					if (res.responseCode !== 200) {
						setErrorMessage("Error: Unable to register as mentor.");
						setShowError(true);
						return;
					}
					navigate("/mentor/home");
				})
		} else if (role == "mentee") {
			let metadata = {
				eduStyle: eduStyle,
				proficiencies: proficiencies
			}

			// Send proficiencies of mentee to database
			let topicProfs = []

			Object.keys(proficiencies).map((proficiency) => {
				let topicProf = {
					topicid: parseInt(proficiency),
					skillrating: proficiencies[proficiency]
				}

				topicProfs.push(topicProf);
			})

			await sendProficiencies(state?.token ?? cToken, topicProfs)
				.then((res) => {
					if (res.responseCode !== 200) {
						setErrorMessage("Error: Unable to register as mentee.")
						setShowError(true);
						return;
					}
				})

			let params = {
				bio: profileDescription,
				areaofinterest: parseInt(topics[0]),
				metadata: JSON.stringify(metadata),
			};

			const profileId = await registerMentee(state?.token ?? cToken, params)
				.then((res) => {
					if (res.responseCode !== 200) {
						setErrorMessage("Error: Unable to register as mentee.");
						setShowError(true);
						return;
					}
					navigate("/mentee/choosementor");
				});
		}
	}


	const validateEmailAndPassword = () => {
		let cErrors = { ...errors }
		let flag = true;

		if (email == "") {
			cErrors["emailError"] = true;
			setEmailMessage("Please enter your email")
			flag = false;
		}
		if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
			cErrors["emailError"] = true;
			setEmailMessage("Please enter a valid email")
			flag = false;
		}

		if (password == "") {
			cErrors["passwordError"] = true;

			setPasswordMessage("Please enter a password")
			flag = false;
		}
		if (!(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(password))) {
			cErrors["passwordError"] = true;
			setPasswordMessage("Password must be at least 8 characters, contain a capital letter, number and special character")
			flag = false;
		}

		if (confirmPassword == "") {
			cErrors["confirmPasswordError"] = true;
			setConfirmPasswordMessage("Please confirm your password")
			flag = false;
		}
		if (password != confirmPassword) {
			cErrors["confirmPasswordError"] = true;
			setConfirmPasswordMessage("Passwords do not match")
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	const validateBasicInfoParams = () => {
		let cErrors = { ...errors };
		let flag = true;

		if (firstName == "") {
			cErrors["firstNameError"] = true;
			flag = false;
		}
		if (lastName == "") {
			cErrors["lastNameError"] = true;
			flag = false;
		}
		if (businessArea == "") {
			cErrors["businessAreaError"] = true;
			flag = false;
		}
		if (jobTitle == "") {
			cErrors["jobTitleError"] = true;
			flag = false;
		}
		if (profileDescription == "") {
			cErrors["profileDescriptionError"] = true;
			flag = false;
		}

		setErrors(cErrors);
		return flag;
	}

	const toggleDialog = () => {
		setShowError(!showError);
	}

	return (
		<React.Fragment>
			{activeStep > 0 ? (
				<Grid container>
					<ErrorDialogWrapper item xs={7}>
						<SharedErrorDialog
							message={errorMessage}
							$show={showError}
							toggleDialog={toggleDialog}
						/>
					</ErrorDialogWrapper>
					<RegistrationWrapper item xs={12}>
						<RegistrationSteps
							handleChange={handleChange}
							handleProfileDescChange={handleProfileDescChange}
							handleRoleChange={handleRoleChange}
							handleTopicsChange={handleTopicsChange}
							handleMetadataChange={handleMetadataChange}
							handleBack={handleBack}
							handleNext={handleNext}
							handleSubmit={handleSubmit}
							values={values}
							errors={errors}
							activeStep={activeStep}
							cToken={cToken}
						/>
					</RegistrationWrapper>
				</Grid>
			) : (
				<RegisterContainer container>
					<Grid item xs={6}>
						<FormWrapper container>
							<Grid item xs={8}>
								<HeaderOne>
									Register
								</HeaderOne>
							</Grid>
							<Grid item xs={8}>
								<SharedErrorDialog
									message={errorMessage}
									$show={showError}
									toggleDialog={toggleDialog}
								/>
							</Grid>
							<Grid item xs={8}>
								<form>
									<Grid>
										<FieldWrapper>
											<FormLabel>Email</FormLabel>
											<TextField
												error={emailError}
												helperText={emailError ? emailMessage : ""}
												value={email}
												required
												fullWidth
												id="email"
												label="Email"
												name="email"
												autoComplete="email"
												onChange={handleChange("email")}
												autoFocus
											/>
										</FieldWrapper>
										<FieldWrapper>
											<FormLabel>Password</FormLabel>
											<TextField
												error={passwordError}
												helperText={passwordError ? passwordMessage : ""}
												value={password}
												label="Password"
												type="password"
												id="password"
												required
												fullWidth
												onChange={handleChange("password")}
											/>
										</FieldWrapper>
										<FieldWrapper>
											<FormLabel>Confirm Password</FormLabel>
											<TextField
												error={confirmPasswordError}
												value={confirmPassword}
												helperText={confirmPasswordError ? confirmPasswordMessage : ""}
												label="Password"
												type="password"
												id="password"
												onChange={handleChange("confirmPassword")}
												required
												fullWidth
											/>
										</FieldWrapper>
										<ButtonWrapper>
											<SharedButton
												type="submit"
												onClick={handleRegister}
												buttonType="Action"
												width="Long"
											>
												Register
											</SharedButton>
										</ButtonWrapper>
										<LinksWrapper container>
											<RegisterWrapper>
												<Caption>Already have an account? </Caption>
												<LinkStyled href="/login" variant="body2">
													Log in.
												</LinkStyled>
											</RegisterWrapper>
										</LinksWrapper>
									</Grid>
								</form>
							</Grid>
						</FormWrapper>
					</Grid>
					<Grid item xs={6}>
						<BoxStyled />
					</Grid>
				</RegisterContainer>
			)
			}
		</React.Fragment>
	)
}

RegisterMain.propTypes = {
	setToken: PropTypes.func.isRequired
}

export default RegisterMain;
