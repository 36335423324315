import React from 'react';
import Login from './components/login/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import useToken from './hooks/useToken';
import MenteeHome from './pages/menteehome';
import MentorHome from './pages/mentorhome';
import MenteeMeetings from './pages/menteemeetings';
import MentorMeetings from './pages/mentormeetings';
import Menteecreatemeeting from './pages/menteecreatemeeting';
import GroupMeetings from './pages/groupmeetings';
import Creategroupmeeting from './pages/creategroupmeeting';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import Color from './assets/colors';
import Register from './pages/register';
import MenteeProfilePage from './pages/menteeprofilepage';
import MenteeEditProfile from './pages/menteeeditprofile';
import MentorProfilePage from './pages/mentorprofilepage';
import MentorEditProfile from './pages/mentoreditprofile';
import MilestoneEdit from './pages/milestoneedit';
import PlanOfAction from './pages/planofaction';
import TaskAdd from './pages/taskadd';
import ViewSession from './pages/viewsession';
import MenteePage from './pages/users/mentees/[id]';
import Feedback from './pages/feedback';
import MentorPage from './pages/users/mentors/[id]';
import ChooseMentorPage from './pages/choose_mentor';
import MyMenteesPage from './pages/mentor/my_mentees';

const theme = createTheme({
  typography: {
    fontFamily: ["Work Sans", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: Color.blue100,
    },
    secondary: {
      main: Color.green100,
    }
  }
});

function App() {
  const { token, setToken } = useToken();

  // Check if logged in user
  // if (!token) {
  //   return (
  //     <Login setToken={setToken} />
  //   )
  // }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Router> 
          <Routes>
            <Route path='/login' element={<Login setToken={setToken} />} />
            <Route path='/register' element={<Register setToken={setToken} />} />
            <Route path='/mentee/home' element={<MenteeHome setToken={setToken} token={token} />} />
            <Route path='/mentor/home' element={<MentorHome setToken={setToken} token={token} />} />
            <Route path='/mentor/createmeeting' element={<Creategroupmeeting setToken={setToken} token={token} />} />
            <Route path='/mentee/createmeeting' element={<Menteecreatemeeting setToken={setToken} token={token} />} />
            <Route path='/mentee/choosementor' element={<ChooseMentorPage token={token} />} />

            <Route path='/mentee/profile' element={<MenteeProfilePage setToken={setToken} token={token} />} />
            <Route path='/mentee/profile/edit' element={<MenteeEditProfile setToken={setToken} token={token} />} />
            <Route path='/mentor/profile' element={<MentorProfilePage setToken={setToken} token={token} />} />
            <Route path='/mentor/profile/edit' element={<MentorEditProfile setToken={setToken} token={token} />} />

            <Route path='/mentee/meetings' element={<MenteeMeetings setToken={setToken} token={token} />} />
            <Route path='/mentor/meetings' element={<MentorMeetings setToken={setToken} token={token} />} />
            <Route path='/mentee/groupmeetings' element={<GroupMeetings setToken={setToken} token={token} />} />

            <Route path='/mentee' element={<Navigate replace to="/mentee/home" />} />
            <Route path='/mentor' element={<Navigate replace to="/mentor/home" />} />
            <Route path='/menteecreatemeeting' element={<Navigate replace to="/mentee/createmeeting" />} />
            <Route path='/' element={<Navigate replace to="/login" />} />
            <Route path='/mentee/planofaction' element={<PlanOfAction setToken={setToken} token={token} />} />
            <Route path='/mentee/planofaction/add' element={<MilestoneEdit setToken={setToken} token={token} />} />
            <Route path='/mentee/planofaction/addtask' element={<TaskAdd setToken={setToken} token={token} />} />
            <Route path='/mentor/my_mentees' element={<MyMenteesPage token={token} setToken={setToken} />} />

            <Route path='/session' element={<ViewSession token={token} />} />

            <Route path='/users/mentees/:id' element={<MenteePage />} />
            <Route path='/users/mentors/:id' element={<MentorPage />} />
            <Route path='/feedback' element={<Feedback />} />

          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;